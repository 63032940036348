/**
 * @module SalesFlow/view
 */
import { Constants } from 'core/constants';

import Subscription from 'model/type/subscription';
import VluxOffer from 'model/type/offer';
import VluxOfferService from 'model/type/offer-service';
import ViewOfferTariffCosts from '../offer-tariff-costs';
import VluxPPOfferService from 'model/type/pp-offer-service';
import VluxPPOfferServiceGroup from 'model/type/pp-offer-service-group';

import Offer from './offer';

import DeviceOffer from './device-offer';

interface ShortServiceJson {
    id: number;
    label: string;
}

/**
 * @TODO To me clear in class naming: This should be named and imported as FrontendDeviceOffer
 */
export default class SimOnlyOffer extends Offer {

    public readonly type: string = 'simonly';

    get subscriptionId (): number {

        if (undefined === this._subscription) {
            return undefined;
        }

        return this._subscription.id;

    }

    /**
     * Cumulated monthly price inluding optional services
     * excluding mandatory services and discounts
     */
    get monthlyDiscountPrice (): number {
        return this._monthlyDiscountPrice;
    }

    /**
     * cumulated regular price without any discounts/mandatory services
     */
    get monthlyRegularPrice (): number {
        return this._monthlyRegularPrice;
    }

    /**
     * get all services where the customer has the choice to select
     * and the customer selected it
     * Skip insurance if included for 0 euro in subscription
     */
    get optionalServices (): VluxOfferService[] {

        const ret: VluxOfferService[] = [];

        for (const serviceGroup of this._offer.getOptionalServices()) {
            for (const service of serviceGroup.services) {

                if (true === this._offer.hasHandyInsuranceIncluded()) {
                    if ((158 === service.id || 159 === service.id) && 0 === service.monthlyPrice.value) {
                        continue;
                    }
                }

                if (-1 < this._optionalServices.indexOf(service.id)) {
                    ret.push(service);
                }
            }
        }

        return ret;
    }

    /**
     * get all services where the customer has no choice
     */
    get mandatoryServices (): VluxOfferService[] {

        const ret: VluxOfferService[] = [];

        for (const serviceGroup of this._offer.getIncludedServices()) {

            for (const service of serviceGroup.services) {
                /**
                 * display only the services the customer has to pay for....
                 */
                let display = false;
                try {
                    if (0 < service.prices[0].value) {
                        display = true;
                    }
                } catch (e) {
                }

                if (display) {
                    ret.push(service);
                }

            }

        }

        return ret;
    }

    /**
     * co-27531 get all discounts where the customer has the choice to select
     * and the customer selected it
     */
    get selectedOptionalDiscounts (): VluxOfferService[] {

        const ret: VluxOfferService[] = [];

        try {
            for (const serviceGroup of this._offer.getOptionalDiscounts()) {
                for (const service of serviceGroup.services) {
                    if (-1 < this._optionalServices.indexOf(service.id)) {
                        ret.push(service);
                    }
                }
            }
        } catch (e) {
        }

        return ret;
    }

    /**
     * get all services where the customer has the choice to select
     * Skip insurance if included for 0 euro in subscription
     */
    get optionalDiscounts (): VluxOfferService[] {

        const ret: VluxOfferService[] = [];

        try {
            for (const serviceGroup of this._offer.getOptionalDiscounts()) {
                for (const service of serviceGroup.services) {
                    ret.push(service);
                }
            }
        } catch (e) {
        }

        return ret;
    }

    /**
     * get all discounts where the customer has no choice
     */
    get mandatoryDiscounts (): VluxOfferService[] {

        const ret: VluxOfferService[] = [];

        for (const serviceGroup of this._offer.getIncludedDiscounts()) {

            for (const service of serviceGroup.services) {
                ret.push(service);

            }

        }

        return ret;
    }

    get privatePricingDiscounts (): VluxOfferService[] {
        const ret: VluxOfferService[] = [];

        try {
            for (const serviceGroup of this._offer.getIncludedDiscounts()) {
                for (const service of serviceGroup.services) {
                    if (-1 < Constants.PPDiscountIds.indexOf(service.id)) {
                        ret.push(service);
                    }
                }
            }
        } catch (e) {}

        return ret;
    }

    get subscription (): Subscription {
        return this._subscription;
    }

    /**
     * Florian: Add override possibility for some special IDs
     *
     * @return string Name of the Tariff
     */
    get subscriptionName (): string {

        if (undefined === this._subscription) {
            return '';
        }

        const subscriptionNameOverrideMapping = {
            [Constants.RedXL_2017_Id]: this._subscription.name + ' 2017'
        };

        if (undefined !== subscriptionNameOverrideMapping[this._subscription.id]) {
            return subscriptionNameOverrideMapping[this._subscription.id];
        }

        return this._subscription.name;

    }

    get offer (): VluxOffer {
        return this._offer;
    }

    get offerTariffCosts (): ViewOfferTariffCosts {
        return this._offerTariffCost;
    }

    get redPlusOffers (): DeviceOffer[] {
        return this._redPlusOffers;
    }

    /**
     * get list of all included services and discounts, that has a benefit for the customer
     */
    get includedServicesAndDiscounts (): ShortServiceJson[] {

        let includesServices: VluxOfferService[] = [];

        for (const serviceGroup of this._offer.getIncludedServices()) {

            for (const service of serviceGroup.services) {

                if (true === service.hidden) {
                    continue;
                }

                /**
                 * display only the services the customer doesn't have to pay for....
                 */
                let display = true;
                try {
                    if (0 < service.prices[0].value) {
                        display = false;
                    }
                } catch (e) {
                }

                if (display) {
                    includesServices.push(service);
                }

            }

        }

        /**
         * special treatment of Device Insurance as this comes in optionalServices
         * but has price of 0 in case of tariff Xl and XXL
         */
        if (this.offer.hasHandyInsuranceIncluded()) {
            includesServices.push(this.offer.getHandyInsuranceIncluded());
        }

        for (const serviceGroup of this._offer.getIncludedDiscounts()) {

            for (const service of serviceGroup.services) {

                if (true === service.hidden) {
                    continue;
                }

                includesServices.push(service);

            }

        }

        /**
         * Sort regular services & discounts that come from offer
         */
        includesServices = includesServices.sort(function (serviceA: VluxOfferService, serviceB: VluxOfferService) {

            if (serviceA.id < serviceB.id) {
                return -1;
            }

            if (serviceA.id > serviceB.id) {
                return 1;
            }

            return 0;
        });

        /**
         * Add mandatory Services and Discounts from Red+ Offers
         */
        for (const redplusOffer of this.redPlusOffers) {

            for (const mandatoryDiscount of redplusOffer.mandatoryDiscounts) {

                if (true === mandatoryDiscount.hidden) {
                    continue;
                }

                includesServices.push(mandatoryDiscount);

            }

        }

        const items: ShortServiceJson[] = includesServices.map(function (service: VluxOfferService) {
            return {
                id: service.id,
                label: service.label
            };
        });

        return items;

    }

    get includedServiceAndDiscountIds (): number[] {

        const ids: number[] = this.includedServicesAndDiscounts.map((service: ShortServiceJson) => {
            return service.id;
        });

        /**
         * special treatment of Device Insurance as this comes in optionalServices
         * but has price of 0 in case of tariff Xl and XXL
         */
        if (this.offer.hasHandyInsuranceIncluded()) {
            ids.push(this.offer.getHandyInsuranceServiceId());
        }

        return ids;

    }

    get monthyDiscountHint (): string {
        let annotate: string = '';

        if (undefined !== this.offerTariffCosts) {
            if (0 < this.offerTariffCosts.discountPrices.length) {
                annotate += 'Ab dem ';
                annotate += (this.offerTariffCosts.shortestDiscount + 1);

                annotate += '. Monat ';

                let undiscountedPrice: string = '' + (Math.round(this.offerTariffCosts.priceArray[this.offerTariffCosts.shortestDiscount] * 100) / 100).toFixed(2);
                undiscountedPrice = undiscountedPrice.replace('.', ',') + '&nbsp;&euro;';

                annotate += undiscountedPrice;
            }
        }

        return annotate;

    }

    /**
     * headline above "Dein gewähltes Paket": different in sim-only offer and divice-offer
     */
    get labelMainProposition (): string {

        if (undefined === this._subscription) {
            return '';
        }

        return this._subscription.name;

    }

    /**
     * Headline in pricebox. In this case logic out of template, because of further expansions (Voice / Data)
     */
    get labelHeaderOnetime (): string {
        return '';
    }

    /**
     * Cumulated Onetime Price inluding connection Fee
     */
    get onetimePrice (): number {

        /**
         *    Device Price
         *  + Connection Fee
         *  + Accessory
         *  + Red+ Connection Fee
         *  + Red+ Device price
         */

        let cumulatedCosts: number = this._offerTariffCost.connectionFee;

        for (const redplusOffer of this.redPlusOffers) {

            cumulatedCosts += redplusOffer._offerTariffCost.connectionFee;

        }

        /*
         * Red unlimited is special:
         * It is currently the subscription that can be simonly with accessories and with devices added to red +
         * That's because red unlimited uses connect more to add/remove red +
         */
        if (Constants.Unlimited_Group_Id === this._subscription.subscriptionGroupId && true === this.isSimOnly()) {

            for (const redplusOffer of this.redPlusOffers) {

                /*
                    It hurts in my heart, but I do not find the underlying error.
                    Therefore, from desperation: try and catch
                 */
                try {
                    cumulatedCosts += redplusOffer.onetimeDevicePrice;

                }

                catch (e) {
                }
            }

        }

        return cumulatedCosts;
    }

    get hasPrivatePricingDiscount (): boolean {
        return (0 < this.privatePricingDiscounts.length);
    }

    /**
     * Cumulated Onetime Price excluding connection Fee
     */
    get onetimePriceWithoutConnectionFee (): number {

        if (Constants.Unlimited_Group_Id === this._subscription.subscriptionGroupId && true === this.isSimOnly()) {
            const cumulatedCosts: number = this.onetimePrice;

            return cumulatedCosts - this._offerTariffCost.connectionFee;
        }

        return 0;

    }

    /**
     * For now it's a hardcoded solution for double data in
     * All simonly offers in young and all red
     */
    get hasPromo (): boolean {

        if (Constants.BTX_BNT !== this.offer.btx) {

            return false;

        }

        if ('simonly' !== this.type) {

            return false;

        }

        if (Constants.Red_Group_Id === this.subscription.subscriptionGroupId) {

            return true;

        }

        if (Constants.Young_Group_Id === this.subscription.subscriptionGroupId) {

            return true;

        }

        return false;

    }

    get promoLabel (): string {

        if (false === this.hasPromo) {

            return '';

        }

        if (Constants.Red_Group_Id === this.subscription.subscriptionGroupId) {

            return 'Doppeltes Datenvolumen';

        }

        if (Constants.Young_Group_Id === this.subscription.subscriptionGroupId) {

            return '50% mehr Datenvolumen';

        }

        return 'Doppeltes Datenvolumen';
    }

    get promoText (): string {

        if (false === this.hasPromo) {

            return '';

        }

        if (Constants.Red_Group_Id === this.subscription.subscriptionGroupId) {

            return 'Du bekommst insgesamt <span class="promoHighlight">' + 2 * this.subscription.dataVolume + ' GB/Monat</span> über die gesamte Vertragslaufzeit.';

        }

        if (Constants.Young_Group_Id === this.subscription.subscriptionGroupId) {

            return 'Du bekommst insgesamt <span class="promoHighlight">' + 1.5 * this.subscription.dataVolume + ' GB/Monat</span> über die gesamte Vertragslaufzeit.';

        }

        return 'Du bekommst insgesamt <span class="promoHighlight">' + 2 * this.subscription.dataVolume + ' GB/Monat</span> über die gesamte Vertragslaufzeit.';

    }
}
