/**
 * @module SalesFlow/evolved/controller
 */

import { Constants } from 'core/constants';

import Injector from 'core/injector';

import DeviceList from 'view/element/bnt/device-list';
import Pricebox from 'view/element/shared/pricebox';

import Filters from 'view/element/shared/filter/filters';

import Subscription from 'model/type/subscription';
import AtomicDevice from 'model/type/atomic-device';
import SubscriptionGroupSwitcher from 'view/element/bnt/subscription-group-switcher';

import Offer from 'view/view/shared/offer/offer';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import HardwareOffer from 'view/view/shared/offer/hardwareonly-offer';

import {SubscriptionList} from 'view/element/shared/subscription-list';
import {FlowCtas} from 'view/ctas/shared/flow-ctas';
import {DeviceOverviewPageState} from 'controller/shared/device-overview-controller';
import {ControllerEvolvedFlowBase} from 'controller-evolved/controller-evolved--flow-base';
import {AbstractSubscriptionGroupSwitcher} from 'view/element/shared/subscription-group-switcher';
import {SalesChannelName, SubscriptionIdPerSalesChannel} from 'core/ids';
import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import Customer from 'shopbackend/customer';
import {ViewEvolvedElementVvlFafStepper} from 'view-evolved/element/vvl/view-evolved--element-vvl--stepper-faf';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

declare var $: JQueryStatic;

/**
 * @internal
 * I come from previous step:
 *      => Tariff is locked
 */
export abstract class ControllerEvolvedSharedDeviceOverview extends ControllerEvolvedFlowBase {

    protected _salesChannel: SalesChannelName;

    protected _atomicDeviceId: number;
    protected _subscriptionId: number;

    protected _pageState: DeviceOverviewPageState;

    protected _pricebox: Pricebox;

    protected _customer: Customer;

    private _subscriptionGroupSwitcher: AbstractSubscriptionGroupSwitcher;

    protected _subscriptionSelection: SubscriptionList<Offer[]>;

    protected _filters: Filters;

    /** @TODO Should not be any ;) */
    protected _deviceList: any;

    protected _subscription: Subscription;

    protected _focusSubscriptionId: SubscriptionIdPerSalesChannel;

    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        focusSubscriptionId: SubscriptionIdPerSalesChannel,
        customer: Customer,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super(reposSupervisor, injector);

        this._salesChannel = salesChannel;

        this._atomicDeviceId = atomicDeviceId;
        this._subscriptionId = subscriptionId;

        this._customer = customer;

        this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(this._atomicDeviceId, true);
        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this._subscriptionId, true);

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('RealProductOverviewPage....');
        }

        this._subscriptionGroupSwitcher = this.createSubscriptionGroupSwitcher();

        this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId);

        this._pricebox = new Pricebox(injector);

        this._focusSubscriptionId = focusSubscriptionId;

        this._subscriptionSelection = this.createSubscriptionList();
        this._filters = new Filters(injector);
        this._deviceList = new DeviceList(injector);

        this._generalSalesObjectInterface = injector.getGeneralSalesObjectInterface();

    }

    protected abstract createSubscriptionList (): SubscriptionList<DeviceOffer[]>;

    protected createCtas (): FlowCtas {
        return undefined;

    }

    protected createSubscriptionGroupSwitcher (): AbstractSubscriptionGroupSwitcher {
        return new SubscriptionGroupSwitcher(
            this.getInjector()
        );
    }

    protected getCustomer (): Customer {
        return this._customer;
    }

    protected hasCustomer (): boolean {
        return (undefined !== this._customer);
    }

    /**
     * Setting tracking on page load. If a tariff and a device has been selected, we send 'product detail' as pageType, else we send 'product listing' as pageType.
     */
    protected tracking () {
        if (false === this._pageState.canBePurchased()) {
            this.getInjector().getEvent().trigger('pageviewTracking@onload',
                {
                    subscription: this._subscription,
                    pageName: 'handys',
                    pageType: 'product listing',
                    deviceOffer: this._subscription // TODO Tracking <- add products
                }
            );
        } else if (undefined !== this._subscriptionId && undefined !== this._atomicDeviceId) {
            this.getInjector().getEvent().trigger('pageviewTracking@onload',
                {
                    subscription: this._subscription,
                    pageName: 'handys',
                    pageType: 'product detail',
                    deviceOffer: this._subscription // TODO Tracking <- add products
                }
            );
        }
    }

    protected render (): void {

        this._subscriptionSelection.update(
            this.getInjector().getOfferCollection().getSubscriptions()
        );
    }

    protected events (): void {

        this.getInjector().getEvent().listen('atomicDeviceId@changed', (eventObject: JQueryEventObject, data: any) => {

            const atomicDeviceId: number = data.atomicDeviceId;

            this._atomicDeviceId = atomicDeviceId;

            this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId, this.getInjector().getFlowStateWithSalesChannel().getHardwareOnly());

            // A devive is selected, but not yet a subscription -> Scroll to scubsriptions
            if (undefined !== this._atomicDeviceId && undefined === this._subscriptionId) {

                $('html, body').animate({
                    scrollTop: $('#subscriptions').offset().top - 20
                }, 1000);
            }

            this._subscriptionSelection.update(this.getSubscriptions());

            // atomicDeviceId has changed and is now undefined or no subscription is selected
            // -> We can make no offer
            if (undefined === atomicDeviceId && (undefined === this._subscriptionId || Constants.Black_Id === this._subscriptionId)) {
                this._pricebox.hide();
                this.getInjector().getEvent().trigger('offer@none');

                return;
            }

            const offer: Offer = this.getActiveOffer();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: offer
            });

            if (true !== data.avoidTracking) {
                this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                    pageName: 'handys',
                    pageType: 'product listing',
                    deviceOffer: offer
                });
            }

        });

        this.getInjector().getEvent().listen('subscriptionId@changed', (eventObject: JQueryEventObject, data: any) => {

            this._subscriptionId = data.subscriptionId;

            this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId);

            this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());
            const devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(this.getSalesChannel(), this._subscription);

            const deviceOffers = devices.map(device => {

                /**
                 * Subscription is locked, so I use the first atomic device of the virtual device
                 */
                const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                    device.getAtomicDeviceByIndex(0).id,
                    this._subscription.id,
                    this._btx,
                    this.getSalesChannel()
                );

                return new DeviceOffer(device.getAtomicDeviceById(offer.deviceId), this._subscription, offer, this.getRedPlusFromFlow(), this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements);

            });

            this.getInjector().getOfferCollection().setDevices(deviceOffers);

            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this.getSubscriptionId());

            this._deviceList.update(this.getInjector().getOfferCollection().getDevices());

            const selectedDeviceOffer = this.getActiveOffer();

            this.getInjector().getOfferCollection().setActiveOffer(selectedDeviceOffer);

            this.setSubscriptionName(this._subscription.name);

            // @TODO the next line where there, but noz commented. No idea why
            // this.getInjector().getFlowStateWithSalesChannel().setAtomicDeviceId(selectedDeviceOffer.atomicDeviceId);

            this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(selectedDeviceOffer.subscriptionId);

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: selectedDeviceOffer
            });

            /**
             * If a tariff and device have been selected, send 'product detail' as pageType, if not then send 'product listing' as pageType
             */
            if (true !== data.avoidTracking) {
                if (undefined !== this._subscriptionId && undefined !== this._atomicDeviceId) {
                    this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                        pageName: 'handys',
                        pageType: 'product detail',
                        deviceOffer: selectedDeviceOffer
                    });
                } else {
                    this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                        pageName: 'handys',
                        pageType: 'product listing',
                        deviceOffer: selectedDeviceOffer
                    });
                }
            }

        });

        /**
         * @TODO This should be moved to bnt
         */
        this.getInjector().getEvent().listen('hardwareonly@selected', (eventObject: JQueryEventObject, data: any) => {

            this.getInjector().getFlowStateWithSalesChannel().setHardwareOnly(true);
            this.getInjector().getFlowStateWithSalesChannel().lockSubscription();

            /**
             * reset Red+ Data
             */
            this.getInjector().getFlowStateWithSalesChannel().resetRedPlusFlowData();

            this._subscriptionId = undefined;

            this._pageState = new DeviceOverviewPageState(this._subscriptionId, this._atomicDeviceId, true);

            $('#nsf-tariff-name').html('Smartphone ohne Tarif');

            const devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(this.getSalesChannel());
            const salesChannel = this.getInjector().getFlowState().getSalesChannel();
            const deviceOffers = devices.map(device => {

                /**
                 * Subscription is locked, so I use the first atomic device of the virtual device
                 */
                const offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                    device.getAtomicDeviceByIndex(0).id,
                    salesChannel
                );

                return new HardwareOffer(device.getAtomicDeviceById(offer.deviceId), offer);

            });

            this.getInjector().getOfferCollection().setDevices(deviceOffers);

            this._deviceList.update(
                this.getInjector().getOfferCollection().getDevices()
            );

            const selectedDeviceOffer = this.getActiveOffer();

            this.getInjector().getOfferCollection().setActiveOffer(selectedDeviceOffer);

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: selectedDeviceOffer
            });

            if (true !== data.avoidTracking) {
                this.getInjector().getEvent().trigger('pageviewTracking@changed', {
                    pageName: 'handys',
                    pageType: 'product listing',
                    deviceOffer: selectedDeviceOffer
                });
            }

        });

        /**
         * When switching between subscriptionGroups no subscription should be selected,
         * even if a subsciption was selected in previous group
         * In this case we need to reset subscription and offer
         */
        this.getInjector().getEvent().listen('subscription@no-active', (eventObject: JQueryEventObject, groupName: string) => {

        });

        this.getInjector().getEvent().listen('subscriptionGroupName@changed', (eventObject: JQueryEventObject, groupName: string) => {

            this._subscriptionId = this.getInjector().getFlowStateWithSalesChannel().getSubscriptionId();

            if (undefined === this._subscriptionId) {

                this._pricebox.hide();
                this.getInjector().getEvent().trigger('offer@none');

            }

        });

    }

    public setSubscriptionName (subscriptionName: string): void {
        $('#nsf-tariff-name').html(subscriptionName);
    }

    /**
     * Did the user opts in a RedPlus Option
     * @TODO THis should not be any[]
     */
    protected getRedPlusFromFlow (): any[] {

        const offersRedPlus: any[] = [];

        for (const offerId of this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements.concat(this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements.concat(this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements))) {

            const redPlusOffer = this._generalSalesObjectInterface.getRedPlusOfferById(
                offerId,
                this.getSalesChannel());
            const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(redPlusOffer.subscriptionId);

            if ('HmK' === redPlusOffer.offerType) {
                const redPlusAtomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(redPlusOffer.deviceId, this.getSalesChannel(), this._subscription);
                offersRedPlus.push(new DeviceOffer(redPlusAtomicDevice, redPlusSubscription, redPlusOffer));
            } else {
                offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
            }

        }

        return offersRedPlus;

    }

    protected getActiveSimOnlyOffer (): SimOnlyOffer {

        const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this._subscriptionId);

        return new SimOnlyOffer(
            subscription,
            this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(this._subscriptionId, this._btx, this.getSalesChannel()),
            this.getRedPlusFromFlow(),
            this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
        );

    }

    protected getActiveDeviceOffer (): DeviceOffer {

        let activeOffer: DeviceOffer = undefined;

        for (const deviceOffer of this.getInjector().getOfferCollection().getDevices()) {

            for (const atomicDevice of deviceOffer.atomicDevice.device.getAtomicDevices()) {

                if (this._atomicDeviceId === atomicDevice.id) {

                    activeOffer = deviceOffer;
                    if (activeOffer.isDevice()) {
                        activeOffer.setIsDeviceSelected(true);
                    }
                    break;
                }
                else {
                    deviceOffer.setIsDeviceSelected(false);
                }
            }

        }

        return activeOffer;

    }

    protected getActiveOffer (): Offer {

        if (false === this._pageState.canBePurchased()) {

            return undefined;

        }

        if (undefined === this._atomicDeviceId && undefined !== this._subscriptionId) {

            return this.getActiveSimOnlyOffer();

        }

        // if an atomicDeviceId is set in flow: Try to find that, select it and overwrite default
        return this.getActiveDeviceOffer();

    }

    protected getSubscriptionId (): number {

        return this._subscriptionId;

    }

    protected getSubscriptionsSimOnlyOffers (): SimOnlyOffer[] {

        const simOnlyOffers: SimOnlyOffer[] = [];

        const salesChannel = this.getInjector().getFlowState().getSalesChannel();

        for (const subscriptionId of this._subscriptionSelection.getEvolvedSubscriptionIds(this._salesChannel, this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup())) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

            let offer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(subscriptionId, this._btx, this.getSalesChannel());

            if (undefined === offer) {
                /**
                 * sometimes, vlux forgets to configure simOnly Offer. So this is a kind of workaround to fix this
                 * problem
                 */
                offer = this._generalSalesObjectInterface.getFirstAvailableOfferBySubscriptionId(subscriptionId, this._btx, salesChannel);
            }

            simOnlyOffers.push(new SimOnlyOffer(subscription, offer));

        }

        return simOnlyOffers;
    }

    protected getSubscriptionsOffers (): DeviceOffer[] {

        const offers: DeviceOffer[] = [];

        const subscriptionIds = this._subscriptionSelection.getSubscriptionIds();

        for (const subscriptionId of subscriptionIds) {

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(subscriptionId);

            const atomicDevice = this.getReposSupervisor().getPurchasableDeviceRepo().getAtomicDevice(
                this._atomicDeviceId,
                this.getSalesChannel(),
                subscription
            );

            if (undefined === atomicDevice) {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"][data-tariff-group="' + this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup() + '"]').hide().addClass('hide');
                continue;
            } else {
                $('.tariff-module-tile[data-subscription-id="' + subscription.id + '"][data-tariff-group="' + this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup() + '"]').show().removeClass('hide');
            }

            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                atomicDevice.id,
                subscription.id,
                this._btx,
                this.getSalesChannel()
            );

            /** not every device is offered for each tariff */
            if (undefined !== offer) {
                // @TODO Fetch redplus from activeOffer, too
                offers.push(
                    new DeviceOffer(
                        atomicDevice,
                        subscription,
                        offer
                    )
                );
            }

        }

        return offers;
    }

    protected getSubscriptions (): Offer[] {

        if (undefined === this._atomicDeviceId) {
            return this.getSubscriptionsSimOnlyOffers();
        }

        return this.getSubscriptionsOffers();
    }

    protected getDeviceOffersWithoutActiveSubscription (): DeviceOffer[] {

        /** as we don't have a subscription at this point, I presume
         *  we're looking for hardware only purchasable devices
         */
        const devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(this.getSalesChannel());

        const subscriptionGroupId = this.getReposSupervisor().getSubscriptionRepo().getSubscriptionGroupIds(
            this.getInjector().getFlowStateWithSalesChannel().getSubscriptionGroup()
        );

        /**
         * get all subscriptions except RedXL and RedL
         */
        let  subscriptions = this.getReposSupervisor().getSubscriptionRepo().getSubscriptions(subscriptionGroupId);
        subscriptions = subscriptions.filter (subscription => {
            if (subscription.id === Constants.RedL_Id || subscription.id === Constants.RedXL_Id) {
                return false;
            } else {
                return true;
            }
        });

        return devices.map(device => {

            const atomicDevice: AtomicDevice = device.getAtomicDeviceByIndex(0);

            /**
             * No tariff is locked, so I look for the tariff where the device
             * has the lowest onetime price
             */
            const offer = this._generalSalesObjectInterface.getLowestDevicePriceOfferForAtomicId(
                atomicDevice.id,
                subscriptions,
                this._btx,
                this._salesChannel);

            if (undefined === offer) {
                return;
            }

            const subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(offer.subscriptionId);

            return new DeviceOffer(atomicDevice, subscription, offer, this.getRedPlusFromFlow(), this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements);

        });

    }

    protected getDeviceOffersWithActiveSubscription (): DeviceOffer[] {
        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());

        const devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(this.getSalesChannel(), this._subscription);

        const deviceOffers = devices.map(device => {

            /**
             * Subscription is locked, so I use the first atomic device of the virtual device
             */
            const offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId(
                device.getAtomicDeviceByIndex(0).id,
                this._subscription.id,
                this._btx,
                this.getSalesChannel()
            );

            return new DeviceOffer(
                device.getAtomicDeviceById(offer.deviceId),
                this._subscription, offer,
                this.getRedPlusFromFlow(),
                this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements
            );

        });

        return deviceOffers;
    }

    protected getDeviceOffers (): DeviceOffer[] {

        if (undefined === this._subscriptionId) {
            return this.getDeviceOffersWithoutActiveSubscription();
        }

        return this.getDeviceOffersWithActiveSubscription();
    }

    /**
     * @TODO This should be reviewed
     */
    private resetDedicatedFlowData () {

        let resetData = true;

        // if coming from subscribtion, don't reset
        if ('subscription_overview' === this._stepper.previousStep) {
            resetData = false;
        }

        // reloaded page
        if ('device_overview' !== this._stepper.previousStep) {
            resetData = false;
        }

        /**
         * Remove dedicated Flowdata (Accessories, Optional Services, HandyInsurance, noRedplus)
         */
        if (true === resetData) {
            this.getInjector().getFlowStateWithSalesChannel().resetDedicatedFlowData(true);
        }

    }

    protected fillOfferOfferCollection (): void {

        this.getInjector().getOfferCollection().setDevices(
            this.getDeviceOffers()
        );

        this.getInjector().getOfferCollection().setActiveOffer(
            this.getActiveOffer()
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            this.getSubscriptions()
        );

        this.getInjector().getOfferCollection().log();

    }

    public bind (): void {

        this._stepper.bind();
        this._subscriptionGroupSwitcher.bind();

        this.resetDedicatedFlowData();

        /**
         * hide Headline above Tariffs
         */
        if ('subscription_overview' === this._stepper.previousStep || this.getInjector().getFlowStateWithSalesChannel().isSubscriptionFirstFlow()) {
            $('#subscriptions').hide();
        }

        // Check on page load if the user have a tradein offer selected already
        const optionalServiceIds = this.getInjector().getFlowState().optionalServiceIds.elements;
        const hasIntradeSelected = 0 <= optionalServiceIds.indexOf(Constants.TradeInDiscount_Id );

        if (hasIntradeSelected) {
            const elementsOptionalServices = this.getInjector().getFlowState().optionalServiceIds.elements;
            if (-1 === elementsOptionalServices.indexOf(Constants.TradeInDiscount_Id)) {
                this.getInjector().getFlowState().optionalServiceIds.addElement(Constants.TradeInDiscount_Id );
            }
        }

        this.fillOfferOfferCollection();

        const offer = this.getActiveOffer();

        if (undefined !== offer) {
            this.setSubscriptionName(offer.subscriptionName);
        }

        this._deviceList.bind(
            this.getInjector().getOfferCollection().getDevices()
        );

        this._subscriptionSelection.bind(
            this.getInjector().getOfferCollection().getSubscriptions()
        );

        this._subscriptionSelection.setActiveSubscriptionId(this._subscriptionId);

        this._filters.bind(this.getInjector().getOfferCollection().getDevices());

        this._pricebox.bind(this.getInjector().getOfferCollection().getActiveOffer());

        if (undefined !== this._ctas) {
            this._ctas.bind(offer);
        }

        this.getInjector().getLoadingIndicator().hide();

        this.events();
        this.render();

        if (undefined !== offer && (offer.isDevice() || offer.isHardwareOnly())) {
            const url = offer.atomicDevice.getDevice().getDetailLink(this.getInjector().getOptions().get('device_detail_prefix'));
            this.getInjector().getEvent().trigger('stepper@device_detail_url', url);

            // check, if tradeIn is selected preselect checkbox
            if (hasIntradeSelected) {
                $(`.checkbox-tradeIn-${offer.atomicDevice.device.id}`).addClass('selected');
            }
        }

        this.tracking();
    }
}
