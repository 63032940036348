/**
 * @module SalesFlow/service
 */

import DeviceOffer from 'view/view/shared/offer/device-offer';
import Injector from 'core/injector';
import {Constants} from 'core/constants';
import Offer from 'view/view/shared/offer/offer';
import Subscription from 'model/type/subscription';
import Customer from 'shopbackend/customer';

/**
 * Class for adding gigakombi functionality to other flows (e.g. BNT)
 */
export default class GigakombiDeviceDetailService {
    /**
     * Injector
     */
    private _injector: Injector;

    constructor (injector: Injector) {
        this._injector = injector;
    }

    /**
     * Return the injector
     *
     * @returns Injector
     */
    private getInjector (): Injector {
        return this._injector;
    }

    /**
     * Update the tariff tiles with the Gigakombi promo badge
     *
     * Loops through the offers, removes any other discount badges and adds the Gigakombi discount badge
     *
     * @param offers
     */
    public updateTariffTileWithGigaKombiPromoBadge (offers: Offer[]) {
        for (const offer of offers) {
            const discountText = this.getInjector().getStrings().get('gigakombi.discount.text');
            const tariffTile = $('.tariff-module-tile[data-gigakombi="true"][data-subscription-id="' + offer.subscriptionId + '"]');
            const btx = this.getInjector().getBtx();
            let hideDiscount = false;
            if ( (Constants.BTX_VVL === btx || Constants.BTX_INLIFE === btx)
                && (offer.subscriptionId === Constants.YoungS_Id || offer.subscriptionId === Constants.YoungXS_Id)) {
                hideDiscount = true;
            }
            let vorteil: string = '';

            if (Constants.BTX_VVL === btx || Constants.BTX_INLIFE === btx) {
                const customer = this.getInjector().getFlowStateWithSalesChannel().getCustomer();
                vorteil = customer.hasGigakombiCase;
            } else {
                vorteil = this.getInjector().getGigakombi().getVorteil();
            }

            // Removing badges, they will be rendered below
            $(tariffTile).find('.price-detail .discount').remove();

            if (undefined !== discountText) {
                if ('BR5' !== vorteil
                    && false === hideDiscount) {
                    $(tariffTile).find('.price-info.monthly').parent('.price-detail').prepend(
                        this.getInjector().getTemplates().render('subscription_gigakombi_badge_text', discountText)
                    );
                    $(tariffTile).find('.MonthlyDiscountSum').text(offer.monthlyDiscountGigakombiSum);
                }
            }
        }
    }

    /**
     * Returns the strike price
     *
     * This method takes an offer and a strike price and returns the strike price if the offer is not a Gigakombi offer (in that case it returns the regular price)
     *
     * @param offer
     * @param strike_price
     * @returns number
     */
    public getStrikePrice (offer: Offer, strike_price: number) {

        if (0 < offer.offerTariffCosts.regularPrice) {
            return offer.offerTariffCosts.regularPrice;
        }

        return strike_price;
    }

    /**
     * Updates the data volume on a tariff tile
     *
     * This method takes a tile and an offer and updates the data volume on the tile to the Gigakombi data volume
     *
     * @param tile
     * @param offer
     */
    public updateDataVolumeOnTile (tile: JQuery, offer: Offer) {
        /**
         * .gk-tv            => TV-case => no extra Data-Volume
         * .gk-extravolume   => IP-case => extra Data-volume
         */

        if (offer.isGigakombi) {

            let volume = offer.fullDataVolume + ' GB';
            const extraVolume = offer.extraDataVolume + ' GB';

            if (offer.gigakombiUnlimitedDatavolume
                || offer.subscriptionId === Constants.RedXL_Id) {

                volume = 'unbegrenztem';
                tile.find('.gk-tv').hide();
                tile.find('.pass-area').hide();
            } else {

                if (0 === offer.extraDataVolume) {
                    tile.find('.gk-extravolume').hide();
                    tile.find('.gigakombi-discount').html(offer.monthlyDiscountGigakombiSum.toLocaleString());
                } else {
                    tile.find('.gk-tv').hide();
                }

                tile.find('.gk-unlimited').hide();
            }

            tile.find('.volumen-amount').html(volume);
            tile.find('.extra-volumen-amount').html(extraVolume);
            tile.find('.MonthlyDiscountSum').html(offer.monthlyDiscountGigakombiSum.toLocaleString());
        }
    }

    /**
     * Show dynamic datavolume and discount in tariff detail overlay
     *
     * @param content
     * @param offer
     */
    public updateDataVolumeInOverlay (content: string, offer: Offer) {

        if (offer.gigakombiUnlimitedDatavolume
            || offer.subscriptionId === Constants.RedXL_Id) {

            content = content.replace(/<span class="h3 volumen-amount">(\d+) GB<\/span>/, '<span class="h3 volumen-amount">unbegrenztem<\/span>');
            content = content.replace(/<span class="gigakombi-discount">(\d+)<\/span>/, '<span class="gigakombi-discount">' + offer.monthlyDiscountGigakombiSum.toString() + '<\/span>');
            content = content.replace(/<li class="gk-tv">/g, '<li class="gk-tv" style="display:none;">');
        } else {

            content = content.replace(/<span class="h3 volumen-amount">(\d+) GB<\/span>/, '<span class="h3 volumen-amount">' + offer.fullDataVolume.toString() + ' GB<\/span>');

            if (0 === offer.extraDataVolume) {
                content = content.replace(/<span class="gk-extravolume">/, '<span class="gk-extravolume" style="display:none;">');
                content = content.replace(/<span class="gk-unlimited">/, '<span class="gk-unlimited" style="display:none;">');
                content = content.replace(/<span class="h3 volumen-amount">unbegrenztem<\/span>/, '<span class="h3 volumen-amount">' + offer.fullDataVolume.toString() + ' GB<\/span>');
            }
        }

        content = content.replace(/<span class="gigakombi-discount">(\d+)<\/span>/, '<span class="gigakombi-discount">' + offer.monthlyDiscountGigakombiSum.toString() + '<\/span>');

        return content;
    }

    /**
     * Workaround to remove the VF Pass in GK unlimited case
     * @param optionalServiceIds
     * @param subscription
     */
    public removeVFPassfromGKUnlimitedOffer (optionalServiceIds: number[], subscription: Subscription) {

        const mainSubscriptionGroup = this._injector.getReposSupervisor().getSubscriptionRepo().getSubscription(subscription.id);
        const selectedPassId = parseInt(this._injector.getStorage().getItem('passId'), 10);

        if (0 <= this.getInjector().getGigakombi().getVorteil().indexOf('IP')
            && !isNaN(selectedPassId)) {

            if (mainSubscriptionGroup.id === Constants.RedM_Id) {
                for (let i = 0; i < optionalServiceIds.length; i++) {
                    if (selectedPassId === optionalServiceIds[i]) {
                        optionalServiceIds.splice(i, 1);
                        continue;
                    }
                }
            } else  {
                if (-1 === optionalServiceIds.indexOf(selectedPassId)) {
                    optionalServiceIds.push(selectedPassId);
                }
            }

            // Needed, because there is a strange behaviour without
            const serviceIds = optionalServiceIds.toString();
            optionalServiceIds = serviceIds.split(',').map(Number);

        }

        return optionalServiceIds;
    }
}
