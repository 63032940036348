import {Renderable} from 'view/renderable';

export type NotificationType = 'warning' | 'success' | 'error';

export interface NotificationDataForHBS {
    notificationType: NotificationType;
    notificationContent: string;
    notificationHeadline: string | undefined;
    toggleViewSelector: string;
    additionalCSSClass: string | undefined;
}

export abstract class NotificationBox extends Renderable<NotificationBox> {

    protected _toggleViewIdentifier: string = 'notification-' + Math.floor((Math.random() * 1000) + 1) ;
    protected _toggleViewSelector: JQuery;

    protected abstract _CSSSelectorAsAnchorInDOM: string;
    protected abstract _notificationType: NotificationType;
    protected abstract _dataForNotificationHBS: NotificationDataForHBS;
    protected abstract _notificationContent: string;
    protected abstract _notificationHeadline: string | undefined;
    protected abstract _additionalCSSClassForStyling: string | undefined;

    protected setNotificationDataForHBS () {
        this._toggleViewSelector = $('.ws2-notification[data-hide-and-show-id="' + this._toggleViewIdentifier + '"]');

        this._dataForNotificationHBS = {
            notificationType: this._notificationType,
            notificationContent: this._notificationContent,
            notificationHeadline: this._notificationHeadline,
            toggleViewSelector: this._toggleViewIdentifier,
            additionalCSSClass: this._additionalCSSClassForStyling
        };
    }

    protected abstract events (): void;

    protected render () {

        if (!this.alreadyExists()) {
            const HBSTemplate = 'notification-ws2';
            $(`${this._CSSSelectorAsAnchorInDOM}`).append(
                this.getInjector().getTemplates().render(HBSTemplate, this._dataForNotificationHBS)
            );
         }
    }

    protected alreadyExists (): boolean {

        return (0 < this._toggleViewSelector.length);
    }

    protected shouldRender (): boolean {

        return true;
    }

    public hideNotificationBox () {
        if (this._toggleViewSelector.length) {
            this._toggleViewSelector.remove();
        }
    }

    public bind () {
        if (this.shouldRender()) {
            this.render();
            this.events();
        } else {
            this.hideNotificationBox();
        }
    }
}
