/**
 * @module SalesFlow/controller-evolved
 */

declare var $: JQueryStatic;
declare var vf: any;

import { InlifeSharedController } from 'controller-evolved/inlife/controller-evolved--inlife-shared';

import VluxOffer from 'model/type/offer';
import { ModelEvolvedRepoSupervisor } from 'model-evolved/repo/model-evolved--repo--supervisor';

import { ViewEvolvedElementVvlContractData } from 'view-evolved/element/vvl/view-evolved--element-vvl--contract-data';
import { ViewEvolvedElementInlifeSubscriptionRecommendation } from 'view-evolved/element/inlife/view-evolved--element-inlife--subscription-recommendation';
import { ViewEvolvedInlifeCtasRecommendation } from 'view-evolved/ctas/inlife/view-evolved--ctas--inlife-recommendation';
import {NotificationContractPeriodType} from 'view-evolved/notifications/inlife/notification-contract-period-type';
import {NotificationSwitchToYoung} from 'view-evolved/notifications/inlife/notification-switch-to-young';

import PrivatePricingBadge from 'view/element/vvl/private-pricing-badge';

import { SalesChannelName, ContractPeriodType } from 'core/ids';

import Customer from 'shopbackend/customer';
import Subscription from 'model/type/subscription';
import Pricebox from 'view/element/shared/pricebox';
import Injector from 'core/injector';
import { Constants } from 'core/constants';
import InlifeOffer from 'view/view/shared/offer/inlife-offer';

import Offer from 'view/view/shared/offer/offer';

/**
 * @internal
 */
export class InlifeRecommendationController extends InlifeSharedController {

    protected _customer: Customer;

    // We just need the cheapestSubLevelSubscription from the router
    protected _mainSubscriptionGroup: Subscription;
    protected _mainSubscriptionId: number;
    protected _subLevelSubscription: VluxOffer;
    protected _subLevelSubscriptionId: number;
    protected _privatePricingBadge: PrivatePricingBadge;

    protected _countOfAllowedTariffs: number;
    protected _subLevelAmount: number;
    protected _viewCustomersData: ViewEvolvedElementVvlContractData;
    protected _viewSubscriptionSelection: ViewEvolvedElementInlifeSubscriptionRecommendation;
    protected _notificationContractPeriodType: NotificationContractPeriodType;
    protected _offer: Offer;

    private _pricebox: Pricebox;
    private _element: JQuery;

    constructor (
        salesChannel: SalesChannelName,
        mainSubscriptionGroup: Subscription,
        subLevelSubscription: VluxOffer,
        countOfAllowedTariffs: number,
        subLevelAmount: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {

        super (
            reposSupervisor,
            injector
        );

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('Recommendation Controller Inlife ....');
        }

        /**
         * In inlife we need the main subscription group id (for example: All Red M subscriptions have an id = Constans.RedM_Id) and the sub level subscription id. Following the Red M example, a Red M subscription with 10 euros sub level has an id of 516.
         */

        this._customer = this.getInjector().getFlowStateWithSalesChannel().customer;
        this._mainSubscriptionGroup = mainSubscriptionGroup;
        this._mainSubscriptionId = this._mainSubscriptionGroup.id;

        this._subLevelSubscription = subLevelSubscription;
        this._subLevelSubscriptionId = this._subLevelSubscription.subscriptionId;
        this._countOfAllowedTariffs = countOfAllowedTariffs;
        this._subLevelAmount = subLevelAmount;
        this._viewCustomersData = new ViewEvolvedElementVvlContractData(injector);
        this._viewSubscriptionSelection = new ViewEvolvedElementInlifeSubscriptionRecommendation(this._mainSubscriptionId, injector);

        this._salesChannel = salesChannel;
        this._pricebox = new Pricebox(injector);
        this._element = $('#nsf-subscription-detail-wrapper');
        this._privatePricingBadge = new PrivatePricingBadge(injector, this._element);
        this._ctas = this.createCtas();

    }

    protected createCtas (): ViewEvolvedInlifeCtasRecommendation {
        let showRedXLNotification: boolean = false;
        if (true === this.isRedXLRecommendedTariff(this._subLevelSubscriptionId) && undefined !== this._customer.hasExistingRedPlus && true === this._customer.hasExistingRedPlus) {
            showRedXLNotification = true;
        }

        return new ViewEvolvedInlifeCtasRecommendation (this._injector, this._countOfAllowedTariffs, showRedXLNotification);
    }

    protected render (data?: any): void {
        /**
         * Set 'recommendation-page' class to the module belt to to define specific styles for pricebox and cost overview
         * Set 'inlife-recommendation-page' id to the module belt to define specific styles for inlife inside device-tile.scss
         */
        $('body').find('.module-belt').addClass('recommendation-page');
        $('body').find('.module-belt').attr('id', 'inlife-recommendation-page');

        $('.inlife-list-container').toggleClass('inlife-accordion-is-open');
        $('.inlife-accordion-toggle').css('display', 'none');

        this.createTariffBenefits();

        this.setContractPeriodType(this._customer, this._subLevelSubscription.subscriptionId);

        this.addNotificationContainer();
        this.handleGigakombiNotification(this._mainSubscriptionId);
        this.createWarningNotificationForContractPeriodType();
        this.createNotificationSwitchToYoung();

    }

    protected createTariffBenefits (): void {
        const HBData = this.getBenefitsHBData();

        $('#nsf-subscription-detail-wrapper-main').append(this.getInjector().getTemplates().render('nsf-benefits-inlife', HBData));

        $('#nsf-subscription-detail-wrapper-main').find('.tariff-module-tile[data-subscription-id]').each((index, tariffTile) => {

            if (true === $(tariffTile).hasClass('selected')) {

                // Clone Tariff Details and append it for Benefit-Area
                const cloneTileForBenefits = $(tariffTile).find('.detail ul').clone();
                cloneTileForBenefits.find('li').each((index, element) => {
                    if (element.classList.contains('content-show-pass')) {

                        for (let i = 0; i < element.children.length; i++) {
                            if (element.children[i].classList.contains('pass-area')) {
                                element.children[i].remove();
                            }
                        }
                    }
                });

                const benefitsWrapper = $('#nsf-subscription-detail-wrapper-main').find('.nsf-benefits-inlife-wrapper');
                benefitsWrapper.append(cloneTileForBenefits);

                // Init Footnotes and Tooltips
                vf.footnotes.init(benefitsWrapper);
                vf.tooltip2.init(benefitsWrapper);

                // remove all tariff details without the pass-area
                const manipulateTile = $(tariffTile).find('.detail ul');
                manipulateTile.find('li').each((index, element) => {
                    if (element.classList.contains('content-show-pass')) {
                        element.removeChild(element.firstChild);
                        for (let i = 0; i < element.children.length; i++) {
                            if (!element.children[i].classList.contains('pass-area')) {
                                element.children[i].remove();
                            }
                        }
                    } else {
                        element.remove();
                    }
                });
            }
        });
    }

    protected getBenefitsHBData () {

        let benefitsTitle = this.getInjector().getStrings().get('inlife-benefits.title');
        if (this._customer.isSohoCustomer) {
            benefitsTitle = this.getInjector().getStrings().get('inlife-benefits.soho.title');
        }

        return {
            title: benefitsTitle,
            benefits: ''
        };
    }

    /**
     * adds a div after nsf-subscription-list-slide where all
     * different notifications are collected
     * @protected
     */
    protected addNotificationContainer () {

        // create div
        super.addNotificationContainer();

        $('#nsf-subscription-detail-wrapper').after(this._notificationContainer);

    }

    protected createWarningNotificationForContractPeriodType () {

        this._notificationContractPeriodType = new NotificationContractPeriodType(
            this.getInjector(),
            this._contractPeriodType,
            this._customer.isSohoCustomer,
            '#notificationContainer'
        );

    }

    /**
     * Creates a notification that, when the user switches to a young xs or
     * young s tariff, he looses his Gigakombi discount
     * @protected
     */
    protected createNotificationSwitchToYoung () {
        this._notificationSwitchToYoung = new NotificationSwitchToYoung(
            this.getInjector(),
            '#notificationContainer',
            this._mainSubscriptionId
        );

    }

    public events () {
        this.getInjector().getEvent().listen('costoverview@updated', this.handleContractPeriodTypeFootnotes);
    }

    protected getRecommendedOffer (): InlifeOffer {

        const inlifeOfferView = new InlifeOffer(
            this._mainSubscriptionGroup,
            this._subLevelSubscription,
            [],
            [],
            this._subLevelAmount);

        return inlifeOfferView;
    }

    protected showRecommendedOffer (inlifeOffer: any) {
        this.setHeadline(inlifeOffer);

        const viewInlifeOffers: InlifeOffer[] = [];
        viewInlifeOffers.push(inlifeOffer);

        this._viewSubscriptionSelection.getSubscriptionIds(this._mainSubscriptionId);
        this._viewSubscriptionSelection.bind(viewInlifeOffers);
    }

    /**
     * We set part of the headline and editorial the other part. For example, this function sets "RED M 4 GB" inside the subscription tile (the red letter).
     *
     */
    private setHeadline (inlifeOffer: any) {
        let headline = inlifeOffer.subscriptionName + ' ';

        if (inlifeOffer.isGigakombi) {
            inlifeOffer.gigakombiUnlimitedDatavolume ? headline += 'mit unbegrenztem Datenvolumen' : headline += inlifeOffer.fullDataVolume + ' GB';
        } else {
            isNaN(inlifeOffer.fullDataVolume) ? headline += 'mit unbegrenztem Datenvolumen' : headline += inlifeOffer.fullDataVolume + ' GB';
        }

        $('h2#subscriptions span').html(headline);
    }

    public bind (): void {

        this.getInjector().getFlowStateWithSalesChannel().setSubscriptionId(this._subLevelSubscriptionId);

        this._offer = this.getRecommendedOffer();

        this.getInjector().getOfferCollection().setActiveOffer(
            this._offer
        );

        this.getInjector().getOfferCollection().setSubscriptions(
            [this._offer]
        );

        this.showRecommendedOffer(this._offer);

        this._viewCustomersData.bind(this._customer);

        this._privatePricingBadge.bind(this._offer);

        if (undefined !== this._offer) {
            this.getInjector().getLoadingIndicator().hide();
        }

        this.render();
        this.events();

        this._pricebox.bind(this._offer);

        if (undefined !== this._ctas) {
            this._ctas.bind(this._offer);
        }

        super.bind(this._element);

        this.tracking();
    }

}
