/**
 * @module SalesFlow/tracking
 */
import {Constants} from 'core/constants';

declare var $: JQueryStatic;
declare var _ddq: any;

import Injector from 'core/injector';
import Order from 'tracking/Order';
import VvlOrder from 'tracking/VvlOrder';
import Product from 'tracking/Product';
import Proposition from 'tracking/Proposition';

import {IOrder} from 'tracking/interfaces/IOrder';
import {IVvlOrder} from 'tracking/interfaces/IVvlOrder';
import {IProduct} from 'tracking/interfaces/IProduct';
import {IProposition} from 'tracking/interfaces/IProposition';
import GetOrderData from 'tracking/getOrderData';

import TrackFilters from 'tracking/filters';
import {FilterForTracking} from 'tracking/filters';
import Customer from 'shopbackend/customer';

export default class Tracker {

    private _injector: Injector;

    private _getOrderData: GetOrderData;

    private _isPageListing: boolean;
    private _isPageListingToggled: boolean;

    constructor (injector: Injector) {

        this._isPageListing = false;
        this._isPageListingToggled = true;

        this._getOrderData = new GetOrderData(injector);
        this._injector = injector;

        new TrackFilters(this._injector);

        this._injector.getEvent().listen('pageviewTracking@onload', (evt: JQueryEventObject, data: any) => {
            // pageview
            this.trackPageView(data, data.deviceOffer, data.pageName);
        });

        this._injector.getEvent().listen('pageviewTracking@changed', (evt: JQueryEventObject, data: any) => {
            // pageview
            this.trackPageView(data, data.deviceOffer, data.pageName);
        });

        /**
         * This pageview event listener is used for the simple flow. Will be called on load and when the active tariff tile gets changed.
         * We need this due to some different different tracking values
         */
        this._injector.getEvent().listen('pageviewTrackingSimple@changed', (evt: JQueryEventObject, data: any) => {
            // pageview
            this.trackSimplePageView(data.deviceOffer, data.pageName, data.pageType);
        });

        this._injector.getEvent().listen('redPlusTracking@changed', (evt: JQueryEventObject, data: any) => {
            // red+ options
            this.trackSwitchRedPlus(data.redPlusOption, data.redPlusName, data.redPlusSelected, data.storePrefix);
        });

        this._injector.getEvent().listen('accessoryTracking@changed', (evt: JQueryEventObject, data: any) => {
            // switch options
            this.trackSwitchOptions(data.selected, data.serviceName, data.type, data.backendId, data.priceMonthly);
        });

        this._injector.getEvent().listen('tracking@filterChanged', (evt: JQueryEventObject, data: any) => {
            // filter changed
            this.trackFilterChange(data.active, data.added, data.removed, data.deviceOffer);
        });

        this._injector.getEvent().listen('tradeinCheckboxTracking@changed', (evt: JQueryEventObject, data: any) => {

            this.trackTradeinCheckboxSelection(data.tradeinCheckboxIsChecked);
        });

        this._injector.getEvent().listen('tradeinOverlay@openned', (evt: JQueryEventObject, data: any) => {

            this.trackTradeinOverlayLinkClicked(data.linkName);
        });

        $('.mod-tabs li a').on('click', function () {
            // tab changed
            _ddq.push(['OLS Device Detail Tab', {
                tab: $(this).text().trim()
            }
            ]);
        });

        this._injector.getEvent().listen('tracking@Layover', (evt: JQueryEventObject, data: any) => {
            // layover opened
            this.trackLayover(data.name);
        });

        this._injector.getEvent().listen('tracking@LayoverClosed', (evt: JQueryEventObject, data: any) => {
            // layover closed
            this.trackLayoverClosed(data.name);
        });

        this._injector.getEvent().listen('tracking@LayoverCtaClick', (evt: JQueryEventObject, data: any) => {
            // layover closed
            this.trackLayoverCtaClick(data.name);
        });

        this._injector.getEvent().listen('tracking@DeviceReviewed', (evt: JQueryEventObject, data: any) => {
            // write review - clicked
            _ddq.push(['OLS Write Review', {}]);
        });

        this._injector.getEvent().listen('priceboxInteraction@click', (evt: JQueryEventObject, data: any) => {
            // pricebox arrow - clicked

            _ddq.push(['pricebox interaction', {
                action: 'open'
            }]);
        });

        $('body').on('click', '.nsf-tel', (evt: JQueryEventObject) => {
            const $link = $(evt.currentTarget);
            if ($link.attr('href') && 0 === $link.attr('href').indexOf('tel:')) {
                const telNumber = $link.attr('href').replace('tel:', '');
                _ddq.push([
                    'hotline cta',
                    {
                        number: telNumber
                    }
                ]);
            }
        });
    }

    private getAtomicDeviceProduct (atomicDevice: any, offerDevicePrice: any) {
        return new Product(
            String(atomicDevice.backendId),
            atomicDevice.name,
            'device', // static
            1, // static
            String(offerDevicePrice[0].value),
            undefined,
            undefined,
            1 // static
        ) as IProduct;
    }

    private getSubscriptionProduct (subscription: any, offerSubscriptionPrice: any) {
        let offerPriceOnce = '';
        let offerPriceMonthly = '';

        /* This doesn't work for VVL because there is no onetime costs for the tariff
        if (undefined !== offerSubscriptionPrice[0]) {
            offerPriceOnce = String(offerSubscriptionPrice[0].value);
        }
        if (undefined !== offerSubscriptionPrice[1]) {
            offerPriceMonthly = String(offerSubscriptionPrice[1].value);
        }
        */

        if (0 < offerSubscriptionPrice.length) {
            for (let i = 0; i < offerSubscriptionPrice.length; i++) {
                if ('onetime' === offerSubscriptionPrice[i].recurrenceUnit) {
                    offerPriceOnce = String(offerSubscriptionPrice[i].value);
                }
                if ('month' === offerSubscriptionPrice[i].recurrenceUnit) {
                    offerPriceMonthly = String(offerSubscriptionPrice[i].value);
                }
            }
        }

        return new Product(
            String(subscription.id),
            subscription.name,
            'tariff', // static
            1, // static
            offerPriceOnce,
            offerPriceMonthly,
            24, // static
            1 // static
        ) as IProduct;
    }

    private getServiceProduct (optionalService: any) {
        return new Product(
            String(optionalService.backendId),
            optionalService.label,
            'service', // static
            1, // static
            '0.00',
            String(optionalService.monthlyPrice.value),
            optionalService.monthlyPrice.recurrenceEnd,
            1 // static
        ) as IProduct;
    }

    private getTradeinSelectedOptionalDiscount (selectedOptionalDiscount: any) {
        return new Product(
            String(selectedOptionalDiscount.id),
            selectedOptionalDiscount.label,
            'discount',
            1,
            undefined,
            selectedOptionalDiscount.monthlyPrice.value.toFixed(2),
            undefined,
            undefined
        ) as IProduct;
    }

    private getAccessoryProduct (accessory: any) {
        return new Product(
            String(accessory.atomicAccessoryId),
            accessory.name,
            'accessory', // static
            1, // static
            String(accessory.onetimeAccessoryPrice)
        ) as IProduct;
    }

    private getRedPlusProduct (redPlusOffer: any) {
        return new Product(
            String(redPlusOffer.subscriptionId),
            redPlusOffer.subscriptionName,
            'tariff', // static
            1, // static
            String(redPlusOffer.offer.subcriptionPriceOnetime.value),
            String(redPlusOffer.offer.subcriptionPriceMonthly.value),
            redPlusOffer.duration
        ) as IProduct;
    }

    private getMultisimProductsTracking (offer: any) {
        const data = [];
        let watchOffer = offer;
        if (undefined !== offer) {
            if (undefined !== offer.watchOffer) {
                watchOffer = offer.watchOffer;
            }
            /* console.log(offer); */
            /* console.log(watchOffer); */
            if (offer) {
                if (offer.simCardId) {
                    data.push( new Product(
                            String(offer.simCardId),
                            offer.simCard.label,
                            Constants.RedPlus_Data, // static
                            1, // static
                            offer.offer.simPriceOnetime.value,
                            offer.offer.simPriceMonthly.value,
                            24, // static
                            1 // static
                        ) as IProduct
                    );

                }

                if (watchOffer.atomicDevice) {
                    data.push( new Product(
                            String(watchOffer.atomicDevice.id),
                            watchOffer.atomicDevice.name,
                            'bundle', // static
                            1, // static
                            watchOffer.offer.devicePriceOnetime.value,
                            '0',
                            24, // static
                            1 // static
                        ) as IProduct
                    );
                }
            }
        }

        return data;

    }

    private getProductsTracking (deviceOffer: any) {

        const data = [];

        if (undefined !== deviceOffer && [] !== deviceOffer) {
            const atomicDevice = deviceOffer.atomicDevice;
            const subscription = deviceOffer.subscription;
            const optionalServices = deviceOffer.optionalServices;
            const selectedOptionalDiscounts = deviceOffer.selectedOptionalDiscounts;
            const accessories = deviceOffer.accessories;
            const redPlusOffers = deviceOffer.redPlusOffers;
            const offer = deviceOffer.offer;

            if (undefined !== offer) {
                const offerDevicePrice = offer.devicePrice;
                const offerSubscriptionPrice = offer.subcriptionPrice;

                if (undefined !== atomicDevice) {
                    // atomicdevice
                    data.push(this.getAtomicDeviceProduct(atomicDevice, offerDevicePrice));
                }
                if (undefined !== subscription) {
                    // subscription
                    data.push(this.getSubscriptionProduct(subscription, offerSubscriptionPrice));
                }
                if (undefined !== optionalServices) {
                    // optionalService
                    for (const optionalService of optionalServices) {
                        if (undefined !== optionalService) {
                            data.push(this.getServiceProduct(optionalService));
                        }
                    }
                }

                if (undefined !== selectedOptionalDiscounts) {
                    for (const selectedOptionalDiscount of selectedOptionalDiscounts) {
                        if (undefined !== selectedOptionalDiscount && selectedOptionalDiscount.id === Constants.TradeInDiscount_Id) {
                            data.push(this.getTradeinSelectedOptionalDiscount(selectedOptionalDiscount));
                        }
                    }
                }

                if (undefined !== accessories) {
                    // accessory
                    for (const accessory of accessories) {
                        if (undefined !== accessory) {
                            data.push(this.getAccessoryProduct(accessory));
                        }
                    }
                }
                if (undefined !== redPlusOffers) {
                    // red plus
                    for (const redPlusOffer of redPlusOffers) {
                        if (undefined !== redPlusOffer) {
                            data.push(this.getRedPlusProduct(redPlusOffer));
                        }
                    }
                }
            }
        }

        return data;
    }

    private trackPageView (data: any, deviceOffer: any, pageName: string) {

        /**
         * When the customer selects something else than simonly, it will handle it like pagelisting(e.g. detailpage)
         * This Code is a "workaround" for just one case, the pagelisting is normaly not tracked, just in this case without simonly and a deviceOffer
         * If it is a simonly and/or no available deviceOffer, it will track nothing
         */

        if (undefined !== data.deviceOffer && undefined !== data.deviceOffer.type && 'simonly' !== data.deviceOffer.type) {
            if (true === this._isPageListing) {
                this._isPageListingToggled = true;
            }
            this._isPageListing = false;
        } else {
            if (false === this._isPageListing) {
                this._isPageListingToggled = true;
            }
            this._isPageListing = true;
        }

        /**
         * Just track the pageview, if it is a PageListing in a toggle case or a detail page case
         * After it will track, the toggle value will reset
         */
        if (this._isPageListingToggled || false === this._isPageListing || (undefined !== data.overwriteRule && data.overwriteRule)) {
            this._isPageListingToggled = false;

            if (Constants.BTX_VVL === data.btx) {
                this.trackVvlPageView(deviceOffer, pageName, data.pageType, data.customer, data.currentPage);
            } else if (Constants.BTX_INLIFE === data.btx) {
                this.trackInlifePageView(deviceOffer, pageName, data.pageType, data.customer, data.currentPage);
            } else if (Constants.BTX_MULTISIM === data.btx) {
                let pageType = 'product listing';
                if (deviceOffer) {
                    pageType = 'product detail';
                }
                this.trackMultisimPageView(deviceOffer, pageName, pageType, data);
            } else if (Constants.BTX_REDPLUS === data.btx) {
                this.trackRedPlusPageView(deviceOffer, pageName, data.pageType);
            } else {

                const orderType = this._injector.getFlowState().getOrderType();

                if (Constants.OrderType_Data === orderType) {
                    this.trackDataGoPageView(deviceOffer, pageName, data.pageType);
                } else {
                    this.trackBntPageView(deviceOffer, pageName, data.pageType);
                }
            }
        }
    }

    private trackMultisimPageView (offer: any, pageName: string, pageType: string, data: any) {
        const oOrder = this.getMultisimOrder(offer);
        // Dont add oOrder when the arrays are not filled
        if ( oOrder.aProducts && 0 < oOrder.aProducts.length && oOrder.aPropositions && 0 < oOrder.aPropositions.length) {
            _ddq.push([
                'pageview',
                {
                    siteArea: 'mobility', // static
                    platformType: 'responsive', // static
                    loginStatus: this._getOrderData.getLoggedInStatus(),
                    siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_MULTISIM),
                    pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                    flowType: this._getOrderData.getFlowType(this._injector),
                    lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                    customerBaseTariff: data.customerBaseTariff,
                    oOrder: oOrder
                }
            ]);
        }
        else {
            _ddq.push([
                'pageview',
                {
                    siteArea: 'mobility', // static
                    platformType: 'responsive', // static
                    loginStatus: this._getOrderData.getLoggedInStatus(),
                    siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_MULTISIM),
                    pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                    flowType: this._getOrderData.getFlowType(this._injector),
                    lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                    customerBaseTariff: data.customerBaseTariff
                }
            ]);

        }
    }

    private trackBntPageView (deviceOffer: any, pageName: string, pageType: string) {
        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: this._getOrderData.getLoggedInStatus(),
                siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_BNT),
                pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                flowType: this._getOrderData.getFlowType(this._injector),
                businessTransactionType: 'newcontract', // static
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                appliedFilters: this._getOrderData.getAppliedFilters(),
                oOrder: this.getBntOrder(deviceOffer)
            }
        ]);
    }

    private trackDataGoPageView (deviceOffer: any, pageName: string, pageType: string) {
        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: this._getOrderData.getLoggedInStatus(),
                siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_BNT),
                pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                flowType: this._getOrderData.getDataGoFlowType(this._injector),
                businessTransactionType: 'newcontract', // static
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                appliedFilters: this._getOrderData.getAppliedFilters(),
                oOrder: this.getBntOrder(deviceOffer)
            }
        ]);
    }

    private trackRedPlusPageView (deviceOffer: any, pageName: string, pageType: string) {
        let subscriptionOrderType = '';
        if (undefined !== deviceOffer.subscription) {
             subscriptionOrderType = deviceOffer.subscription.orderType;
        }
        const flowType = this._getOrderData.getRedPlusFlowType(this._injector, subscriptionOrderType);
        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: this._getOrderData.getLoggedInStatus(),
                siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_BNT),
                pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                flowType: flowType,
                businessTransactionType: 'newcontract', // static
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                appliedFilters: this._getOrderData.getAppliedFilters(),
                oOrder: this.getBntOrder(deviceOffer)
            }
        ]);
    }

    /**
     * version of pageview tracking for simple flow due to some differences to the 'normal' bnt flow
     *
     * @ TODO: If the simple flow will replace the normal flow permanent after the test period,
     * then trackBntPageView should be changed to this values and this version can be replaced with trackBntPageView
     *
     * @param deviceOffer
     * @param pageName
     * @param pageType
     */
    private trackSimplePageView (deviceOffer: any, pageName: string, pageType: string) {

        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: this._getOrderData.getLoggedInStatus(),
                siteStructure: this._getOrderData.getSimpleSiteStructure(pageName, Constants.BTX_BNT), // different for Simple Flow
                pageType: pageType,
                flowType: this._getOrderData.getFlowType(this._injector),
                businessTransactionType: 'newcontract', // static
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                // appliedFilters: this._getOrderData.getAppliedFilters(), // not set for simple flow
                oOrder: this.getBntOrder(deviceOffer)
            }
        ]);
    }

    private trackInlifePageView (offer: any, pageName: string, pageType: string, customer: Customer, currentPage: string) {
        /**
         * Adversitement indicated that they consider INL24 a new transaction, and therefore the btx should be prolongation. In the case of just upgrading tariffs (INL), the btx should be tariffchange.
         */
        let btxForTracking: string;
        const inlifeFlowType = customer.inlifeFlowType;

        if ('INL' === inlifeFlowType) {
            btxForTracking = 'tariffchange';
        } else if ('INL24' === inlifeFlowType) {
            btxForTracking = 'prolongation';
        }

        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: (customer.isLoggedIn) ? 'logged in' : 'not logged in',
                domain: window.location.host,
                siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_INLIFE),
                pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                contractExtensionEligibility: inlifeFlowType,
                customerSegment: customer.customerSegment,
                flowType: this._getOrderData.getTariffchangeFlowType(currentPage),
                businessTransactionType: btxForTracking,
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                oOrder: this.getVvlOrder(offer, customer)
            }
        ]);
    }

    private trackVvlPageView (deviceOffer: any, pageName: string, pageType: string, customer: Customer, currentPage: string) {
        _ddq.push([
            'pageview',
            {
                siteArea: 'mobility', // static
                platformType: 'responsive', // static
                loginStatus: (customer.isLoggedIn) ? 'logged in' : 'not logged in',
                domain: window.location.host,
                siteStructure: this._getOrderData.getSiteStructure(pageName, Constants.BTX_VVL),
                pageType: pageType, // this._getOrderData.getPageType(this._isPageListing),
                contractExtensionEligibility: customer.prolongationType,
                customerSegment: customer.customerSegment,
                flowType: this._getOrderData.getVvlFlowType(currentPage),
                businessTransactionType: 'prolongation', // static
                lineOfCustomer: this._getOrderData.getLineOfCustomer(),
                oOrder: this.getVvlOrder(deviceOffer, customer)
            }
        ]);
    }

    private trackFilterChange (active: FilterForTracking[], added: FilterForTracking[], removed: FilterForTracking[], deviceOffer: any) {
        const filter = {
            action: 'add filter',
            updatedFilter: added,
            appliedFilters: active
            // oOrder: this.getOrder(deviceOffer)
        };

        if (0 < removed.length) {
            filter.action = 'remove filter';
            filter.updatedFilter = removed;
        }

        // add subscription group to the applied filters
        const tariffGroup = this._injector.getFlow().getSubscriptionGroup();
        let value;
        switch (tariffGroup) {
            case 'consumer':
                value = 'red';
                break;
            case 'young':
                value = 'yolo';
                break;
            case 'easy':
                value = 'easy';
                break;
            default:
                value = 'red';
        }
        const tariffGroupFilter = {
            facet: 'tariff',
            value: value
        };
        filter.appliedFilters.push(tariffGroupFilter);

        _ddq.push(['filter products', filter]);
    }

    private trackSwitchOptions (selected: boolean, serviceName: string, type: string, backendId: string, priceMonthly: number) {
        const data = {selectedOption: [] as any[], removedOption: [] as any[]};

        const optionData = [{
            name: serviceName,
            type: type,
            sku: backendId,
            priceMonthly: priceMonthly,
            proposition: 1
        }];

        if (selected) {
            data.selectedOption = optionData;
        } else {
            data.removedOption = optionData;
        }

        _ddq.push(['switch options', data]);
    }

    private trackSwitchRedPlus (offer: any, redPlusName: string, selected: boolean, storePrefix = '') {
        const data = {selectedOption: [] as any[], removedOption: [] as any[]};

        let name = redPlusName;
        if (Constants.RedPlus_Allnet === redPlusName) {
            name = 'Red+ Allnet';
        } else if (Constants.RedPlus_Data === redPlusName) {
            name = 'Red+ Data';
        } else if (Constants.RedPlus_Kids === redPlusName) {
            name = 'Red+ Kids';
        }

        const optionData = [{
            name: name,
            priceOnce: offer.subcriptionPriceOnetime.value,
            priceMonthly: offer.subcriptionPriceMonthly.value,
            type: 'tariff', // static
            sku: String(offer.offerId),
            duration: 24, // static
            proposition: 1 // static
        }];

        if (selected) {
            data.selectedOption = optionData;
        } else {
            data.removedOption = optionData;
        }

        _ddq.push([storePrefix + 'switch red plus', data]);
    }

    private trackLayover (name: string) {
        _ddq.push(['layover',
            {
                action: 'show', // static
                name: name
            }
        ]);
    }

    private trackLayoverClosed (name: string) {
        _ddq.push(['layover',
            {
                action: 'close', // static
                name: name
            }
        ]);
    }

    private trackLayoverCtaClick (name: string) {
        _ddq.push(['hotline cta',
            {
                number: name
            }
        ]);
    }

    private trackTradeinCheckboxSelection (tradeinCheckboxIsChecked: boolean) {

            const trackingData = [{
                name: Constants.tradeinName,
                type: Constants.tradeinType,
                sku: String(Constants.TradeInDiscount_Id),
                priceMonthly: Constants.tradeinPriceMonthly,
                proposition: Constants.tradeinPoposition
            }];

            if (tradeinCheckboxIsChecked) {
                _ddq.push(['switch options',
                    {
                        selectedOption: trackingData
                    }]);
            } else {
                _ddq.push(['switch options',
                    {
                        removedOption: trackingData
                    }]);
            }
    }

    private trackTradeinOverlayLinkClicked (linkName: string) {
        _ddq.push(['information',
            {
                information_action: 'reveal',
                information_name: linkName,
                information_ui_type: 'layover',
                information_trigger: 'link'
            }]);
    }

    private getProposition (deviceOffer: any, retention?: string) {
        const data = [];

        if (undefined !== deviceOffer && (undefined !== deviceOffer.atomicDevice || undefined !== deviceOffer.subscription)) {
            let deviceName = '';
            let subscriptionName = '';
            let productCategory = '';
            let subscriptionOrderType = '';

            if (undefined !== deviceOffer.atomicDevice) {
                deviceName = deviceOffer.atomicDevice.name;
                productCategory = 'device';
            }
            if (undefined !== deviceOffer.subscription) {
                subscriptionName = deviceOffer.subscription.name;
                subscriptionOrderType = deviceOffer.subscription.orderType;
                productCategory = 'tariff';
            }
            if (undefined !== deviceOffer.subscription && undefined !== deviceOffer.atomicDevice) {
                productCategory = 'bundle';
            }

            const orderType = this._injector.getFlowState().getOrderType();
            if (Constants.OrderType_Data === orderType) {
                subscriptionOrderType = 'data';
            }

            data.push(new Proposition(
                1,
                this._getOrderData.getPropositionName(deviceName, subscriptionName), // name
                this._getOrderData.getPropositionType(), // type
                this._getOrderData.getTargetAudience(),
                retention, // newcontract or prolongation
                'credit', // productType - static
                this._getOrderData.getProductLine(subscriptionOrderType), // productLine
                productCategory, // productCategory
                this._getOrderData.getPropositionUnits()
            ) as IProposition);
        }

        return data;
    }

    private getMultisimProposition (offer: any, retention?: string) {
        const data = [];

        if (undefined !== offer) {
            let deviceName = '';
            let simCardName = '';
            let productCategory = '';
            let watchOffer = offer;

            if (undefined !== offer.watchOffer) {
                watchOffer = offer.watchOffer;
            }

            if (undefined !== watchOffer && undefined !== watchOffer.atomicDevice && undefined !== watchOffer.atomicDevice.name) {
                deviceName = watchOffer.atomicDevice.name;
                productCategory = 'device';
            }
            if (undefined !== offer.simCard && undefined !== offer.simCard.label) {
                simCardName = offer.simCard.label;
                productCategory = 'tariff';
            }

            if (undefined !== watchOffer && undefined !== watchOffer.atomicDevice && undefined !== watchOffer.atomicDevice.name && undefined !== offer.simCard && undefined !== offer.simCard.label) {
                productCategory = 'bundle';
            }

            data.push(new Proposition(
                1,
                this._getOrderData.getPropositionName(deviceName, simCardName), // name
                this._getOrderData.getPropositionType(), // type
                this._getOrderData.getTargetAudience(),
                retention, // newcontract or prolongation
                'credit', // productType - static
                Constants.RedPlus_Data, // productLine
                productCategory, // productCategory
                this._getOrderData.getPropositionUnits()
            ) as IProposition);
        }

        return data;
    }

    private getBntOrder (deviceOffer: any) {

        return new Order(
            this.getProductsTracking(deviceOffer), // products
            this.getProposition(deviceOffer, 'newcontract') // propositions
        ) as IOrder;
    }

    private getMultisimOrder (offer: any) {

        return new Order(
            this.getMultisimProductsTracking(offer), // products
            this.getMultisimProposition(offer, 'newcontract') // propositions
        ) as IOrder;
    }

    private getVvlOrder (deviceOffer: any, customer: Customer) {
        let hasPersonalOffer = false;
        let hasPrivatePricing = false;
        const btx: string = this._injector.getBtx();
        let retention: string;

        if (Constants.BTX_VVL === btx || Constants.BTX_INLIFE === btx) {
            retention = 'prolongation';
        }

        const recommendedDeviceId = this._injector.getFlow().getRecommendedAtomicId();
        const recommendedSubscriptionId = this._injector.getFlow().getRecommendedSubscriptionId();

        if (undefined !== deviceOffer) {
            if (deviceOffer.atomicDeviceId === recommendedDeviceId && deviceOffer.subscriptionId === recommendedSubscriptionId) {
                hasPersonalOffer = true;
            }
            if (deviceOffer.privatePricingDiscounts && 0 < deviceOffer.privatePricingDiscounts.length) {
                hasPrivatePricing = true;
            }
        }

        return new VvlOrder(
            this.getProductsTracking(deviceOffer), // products
            this.getProposition(deviceOffer, retention), // propositions
            customer,
            hasPersonalOffer,
            hasPrivatePricing
        ) as IVvlOrder;
    }
}
